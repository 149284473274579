.pane-news-override-panel-pane-1,
.pane-news-override-panel-pane-2 {
  $inner-col-gap: 48px;
  $inner-row-gap: 24px;
  $image-width: 260px;
  .views-row {
    padding: $inner-row-gap 0;
    border-bottom: 1px solid $wcm-border-gray;
  }
  .field-content {
    display: flex;
    flex-direction: column;
    row-gap: $inner-row-gap;
    @include breakpoint($sm) {
      flex-direction: row;
      column-gap: $inner-col-gap;
    }
  }
  .field-featured-image {
    @include breakpoint($sm) {
      width: $image-width;
      order: 1;
    }
    img {
      width: $image-width;
      height: 176px;
      object-fit: cover;
    }
  }
  .group-text {
    @include breakpoint($sm) {
      width: calc(100% - #{$image-width} - #{$inner-col-gap});
    }
  }
  h3 {
    margin: 0 
  }
  .h6 {
    margin: 12px 0 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 0;
  }
}
