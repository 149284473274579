/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/*** Directory ***/
.view-person-list:before, .view-person-list-directory-override:before, .view-person-list:after, .view-person-list-directory-override:after {
  content: " ";
  display: table; }

.view-person-list:after, .view-person-list-directory-override:after {
  clear: both; }

.view-person-list .view-content, .view-person-list-directory-override .view-content {
  margin-left: -20px;
  margin-right: -20px; }
  @media screen and (min-width: 768px) {
    .view-person-list .view-content, .view-person-list-directory-override .view-content {
      display: flex;
      flex-wrap: wrap; }
      .view-person-list .view-content:before, .view-person-list-directory-override .view-content:before {
        display: block; } }
  .view-person-list .view-content .person-list-item, .view-person-list-directory-override .view-content .person-list-item {
    margin: 0 0 30px; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item, .view-person-list-directory-override .view-content .person-list-item {
        display: flex;
        flex-direction: column; } }
    .view-person-list .view-content .person-list-item .person-display-name, .view-person-list-directory-override .view-content .person-list-item .person-display-name {
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 15px; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:before, .view-person-list-directory-override .view-content .person-list-item .person-display-name .person-name-suffix:before {
        content: ', ';
        position: relative; }
      .view-person-list .view-content .person-list-item .person-display-name .person-name-suffix:empty:before, .view-person-list-directory-override .view-content .person-list-item .person-display-name .person-name-suffix:empty:before {
        content: ''; }
    .view-person-list .view-content .person-list-item .person-headshot, .view-person-list-directory-override .view-content .person-list-item .person-headshot {
      margin-bottom: 5px;
      border-top: 8px solid #b31b1b;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot img, .view-person-list-directory-override .view-content .person-list-item .person-headshot img {
        width: 100%;
        transition: all 0.3s ease 0s; }
      .view-person-list .view-content .person-list-item .person-headshot .view-profile-details, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details {
        cursor: pointer; }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn {
          position: absolute;
          background-color: #b31b1b;
          color: #fff;
          bottom: 0;
          right: 0;
          padding: 3px 10px;
          font-size: 15px; }
          .view-person-list .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details .view-details-btn:after {
            content: "\e80a";
            padding-left: 5px;
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            text-align: center;
            /* opacity: .8; */
            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal;
            text-transform: none;
            /* fix buttons height, for twitter bootstrap */
            line-height: 1em;
            /* Animation center compensation - margins should be symmetric */
            /* remove if not needed */
            /* you can be more comfortable with increased icons size */
            /* font-size: 120%; */
            /* Font smoothing. That was taken from TWBS */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            /* Uncomment for 3D effect */
            /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover img, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details:hover img {
          transform: scale(1.1); }
        .view-person-list .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn, .view-person-list-directory-override .view-content .person-list-item .person-headshot .view-profile-details:hover .view-details-btn {
          background-color: #cf4520; }
    .view-person-list .view-content .person-list-item.is-hover .person-headshot, .view-person-list-directory-override .view-content .person-list-item.is-hover .person-headshot {
      border-top: 8px solid #cf4520; }
      .view-person-list .view-content .person-list-item.is-hover .person-headshot img, .view-person-list-directory-override .view-content .person-list-item.is-hover .person-headshot img {
        transform: scale(1.1); }
    .view-person-list .view-content .person-list-item.is-hover .view-details-btn, .view-person-list-directory-override .view-content .person-list-item.is-hover .view-details-btn {
      background-color: #cf4520 !important; }
      .view-person-list .view-content .person-list-item.is-hover .view-details-btn:after, .view-person-list-directory-override .view-content .person-list-item.is-hover .view-details-btn:after {
        transform: rotate(180deg);
        padding-right: 5px;
        padding-left: 0 !important; }
    @media screen and (min-width: 768px) {
      .view-person-list .view-content .person-list-item.is-hover:after, .view-person-list-directory-override .view-content .person-list-item.is-hover:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #f7f7f7 transparent;
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -20px; } }
    .view-person-list .view-content .person-list-item .person-details-wrapper, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper {
      display: none; }
      .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper .field-person-details {
        background-color: #f7f7f7;
        padding: 15px;
        margin-top: 20px;
        position: relative; }
        .view-person-list .view-content .person-list-item .person-details-wrapper .field-person-details:before, .view-person-list-directory-override .view-content .person-list-item .person-details-wrapper .field-person-details:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 20px 20px 20px;
          border-color: transparent transparent #f7f7f7 transparent;
          position: absolute;
          top: -15px;
          left: 50%;
          margin-left: -20px; }
    .view-person-list .view-content .person-list-item .person-title ul, .view-person-list-directory-override .view-content .person-list-item .person-title ul {
      margin: 0;
      padding: 0; }
      .view-person-list .view-content .person-list-item .person-title ul li, .view-person-list-directory-override .view-content .person-list-item .person-title ul li {
        list-style-type: none;
        margin: 0;
        margin-bottom: 5px; }

.view-person-list .bio-row-container, .view-person-list-directory-override .bio-row-container {
  display: none; }
  .view-person-list .bio-row-container .bio-row-content, .view-person-list-directory-override .bio-row-container .bio-row-content {
    background-color: #f7f7f7;
    padding: 15px;
    margin: 0px 0px 30px;
    position: relative; }
  .view-person-list .bio-row-container.is-hidden, .view-person-list-directory-override .bio-row-container.is-hidden {
    display: none; }

.pane-person-list-panel-pane-2 .pane-title {
  margin-bottom: 20px;
  font-size: 30px; }

.pane-person-list-panel-pane-2 .view-person-list .view-content, .pane-person-list-panel-pane-2 .view-person-list-directory-override .view-content {
  display: block;
  margin-left: 0;
  margin-right: 0; }

.pane-person-list-panel-pane-2 .view-person-list .person-list-item, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item {
  display: block;
  font-size: inherit; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .person-headshot {
      float: left;
      margin-right: 30px; } }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .person-display-name {
    font-family: "1898Sans-Bold", sans-serif;
    font-size: 20px;
    color: #b31b1b; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-department, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-department {
    margin-bottom: 10px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-clinical-url, .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-wcm-person-research-url, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-wcm-person-research-url {
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-field-project-locations .views-label, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-field-project-locations .views-label {
    color: #b31b1b;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-projects {
    margin-top: 10px; }
    .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-projects .featured-projects-heading, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-projects .featured-projects-heading {
      color: #b31b1b;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px; }
  .pane-person-list-panel-pane-2 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-2 .view-person-list-directory-override .person-list-item .views-field-view .view-content {
    margin-left: 0;
    margin-right: 0; }

.pane-person-list-panel-pane-3 {
  background-color: #555555;
  max-width: 300px;
  margin: 30px auto 0; }
  @media screen and (min-width: 768px) {
    .pane-person-list-panel-pane-3 {
      max-width: none; } }
  .pane-person-list-panel-pane-3 .view-person-list, .pane-person-list-panel-pane-3 .view-person-list-directory-override {
    position: relative; }
    .pane-person-list-panel-pane-3 .view-person-list .view-content, .pane-person-list-panel-pane-3 .view-person-list-directory-override .view-content {
      display: block;
      margin-left: 0;
      margin-right: 0; }
    .pane-person-list-panel-pane-3 .view-person-list .person-list-item, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item {
      flex-direction: inherit;
      font-size: inherit;
      margin-bottom: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-headshot {
        border: none; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-headshot, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-headshot {
            float: left;
            width: 30%;
            padding-right: 30px;
            margin-bottom: 0; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .person-display-name, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .person-display-name {
        font-family: "1898Sans-Bold", sans-serif;
        font-size: 20px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-view .view-content, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-view .view-content {
        margin-left: 0;
        margin-right: 0; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .project-locations div, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .project-locations div {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px; }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 {
        position: relative;
        padding: 20px; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 {
            padding: 0 20px 0 30px;
            width: 70%;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .person-details, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .person-details {
              position: relative;
              top: 50%;
              -webkit-transform: translateY(-50%);
              /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
              -ms-transform: translateY(-50%);
              /* IE 9 */
              transform: translateY(-50%);
              /* IE 10, Fx 16+, Op 12.1+ */
              position: absolute;
              width: 100%;
              padding-right: 40px; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects {
          margin-top: 20px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .featured-projects-heading {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px; }
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details {
            display: inline;
            margin: 0; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:after, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details:after {
              content: ', ';
              position: relative;
              margin-left: -4px; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details:last-child:after {
              content: ''; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-1 .view-projects .project-details a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-1 .view-projects .project-details a {
              color: #ffc72c; }
      @media screen and (min-width: 768px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 {
          position: absolute;
          bottom: 0;
          right: 0; } }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 {
          bottom: -45px; } }
      .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a {
        position: relative;
        background: #cf4520;
        padding: 10px 40px;
        border: none;
        margin: 5px 0 0;
        display: block;
        text-align: center; }
        @media screen and (min-width: 768px) {
          .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a {
            margin: 0;
            float: left; }
            .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a:first-child:not(:last-child):before {
              content: '';
              position: absolute;
              right: 0;
              height: 70%;
              top: 15%;
              border: 0.5px solid #fff;
              opacity: 0.5; } }
        .pane-person-list-panel-pane-3 .view-person-list .person-list-item .views-field-nothing-2 a:hover, .pane-person-list-panel-pane-3 .view-person-list-directory-override .person-list-item .views-field-nothing-2 a:hover {
          background: #b31b1b; }

.view-person-list .views-field-field-wcm-person-linkedin-url, .view-person-list-directory-override .views-field-field-wcm-person-linkedin-url,
.node-type-wcm-person .field-wcm-person-linkedin-url {
  margin-top: 5px; }
  .view-person-list .views-field-field-wcm-person-linkedin-url a:before, .view-person-list-directory-override .views-field-field-wcm-person-linkedin-url a:before,
  .node-type-wcm-person .field-wcm-person-linkedin-url a:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    height: 40px;
    background: white url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/linkedin.svg) top left/40px auto no-repeat; }

.view-person-list-directory-override .views-field-view .view-content {
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .view-person-list-directory-override .views-field-view .view-content .views-field-nothing .field-content {
    display: inline-block;
    color: #fff;
    background-color: #cf4520;
    border-radius: .75rem;
    margin-top: 1rem;
    padding: .5rem;
    width: 80%;
    text-align: center; }
    .view-person-list-directory-override .views-field-view .view-content .views-field-nothing .field-content a {
      color: inherit; }

.view-person-list-directory-override .view-content h3 {
  margin-bottom: 1rem;
  width: 100%;
  padding-left: 2rem; }

.pane-news-override-panel-pane-1 .views-row,
.pane-news-override-panel-pane-2 .views-row {
  padding: 24px 0;
  border-bottom: 1px solid #dddddd; }

.pane-news-override-panel-pane-1 .field-content,
.pane-news-override-panel-pane-2 .field-content {
  display: flex;
  flex-direction: column;
  row-gap: 24px; }
  @media screen and (min-width: 768px) {
    .pane-news-override-panel-pane-1 .field-content,
    .pane-news-override-panel-pane-2 .field-content {
      flex-direction: row;
      column-gap: 48px; } }

@media screen and (min-width: 768px) {
  .pane-news-override-panel-pane-1 .field-featured-image,
  .pane-news-override-panel-pane-2 .field-featured-image {
    width: 260px;
    order: 1; } }

.pane-news-override-panel-pane-1 .field-featured-image img,
.pane-news-override-panel-pane-2 .field-featured-image img {
  width: 260px;
  height: 176px;
  object-fit: cover; }

@media screen and (min-width: 768px) {
  .pane-news-override-panel-pane-1 .group-text,
  .pane-news-override-panel-pane-2 .group-text {
    width: calc(100% - 260px - 48px); } }

.pane-news-override-panel-pane-1 h3,
.pane-news-override-panel-pane-2 h3 {
  margin: 0; }

.pane-news-override-panel-pane-1 .h6,
.pane-news-override-panel-pane-2 .h6 {
  margin: 12px 0 20px;
  letter-spacing: .1em;
  text-transform: uppercase; }

.pane-news-override-panel-pane-1 p,
.pane-news-override-panel-pane-2 p {
  margin-bottom: 0; }

@media screen and (min-width: 992px) {
  .footer-nav {
    margin: 0 0 20px; } }

.footer-nav li {
  line-height: 1.4;
  padding: 0 0 8px; }

@media screen and (min-width: 992px) {
  .footer-nav .level-1 {
    width: 20%;
    float: left;
    padding: 0 10px 5px; } }
