.footer-nav {
  @include breakpoint($md) {
    margin: 0 0 20px;
  }
  li {
    line-height: 1.4;
    padding: 0 0 8px;
  }
}

.footer-nav .level-1 {
  @include breakpoint($md) {
    width: (100% / 5);
    float: left;
    padding: 0 10px 5px;
  }
}