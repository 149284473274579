@import "../../../../../profiles/wcmc/modules/custom/wcm_person/css/wcm_person.scss";

// Directory Page
.view-person-list-directory-override{
  // Apply the original SCSS class from the wcm_person module
  @extend .view-person-list;

  .views-field-view{
    .view-content{
      display: block;
      margin-left: auto;
      margin-right: auto;
      .views-field-nothing{
        .field-content{
          display: inline-block;
          color: $wcm-white;
          background-color: $wcm-dark-orange;
          border-radius: .75rem;
          margin-top: 1rem;
          padding: .5rem;
          width: 80%;
          text-align: center;
          a{
            color: inherit;
          }
        }
      }
    }
  }
}
.view-person-list-directory-override .view-content h3 {
  margin-bottom: 1rem;
  width: 100%;
  padding-left: 2rem;
}